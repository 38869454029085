import type {
  UIMediaProps,
  Video,
} from '@design-system/components/UI/UIMedia.props'

declare global {
  interface HTMLVideoElement {
    webkitEnterFullscreen?: () => Promise<void>
    mozRequestFullScreen?: () => Promise<void>
    msRequestFullscreen?: () => Promise<void>
  }
}

export const useVideo = (
  video: UIMediaProps,
  videoRef: Ref<HTMLVideoElement | null>
) => {
  const mediaVideo = video.media as Video

  // Determine if the video is of 'standard' or 'loop' type
  const isStandardVideo = mediaVideo.anatomy === 'standard'
  const isLoopVideo = mediaVideo.anatomy === 'loop' || !isStandardVideo

  // States for playback control
  const isPlaying = ref(mediaVideo.autoplay)
  const isMuted = ref(mediaVideo.muted)

  // Time-related states
  const currentTime = ref('0:00')
  const duration = ref('0:00')
  const currentSeconds = ref(0)
  const videoDuration = ref(0)

  // Computed properties for video sources in different formats
  const srcVideo = computed(() =>
    getVideoScr(mediaVideo, 'webm', video.aspectRatio)
  )

  const srcVideoMp4 = computed(() =>
    getVideoScr(mediaVideo, 'mp4', video.aspectRatio)
  )

  // Check if Picture-in-Picture (PiP) is supported by the browser
  const isPipSupported = computed(
    () => videoRef.value && 'requestPictureInPicture' in videoRef.value
  )

  // Executes a given function if the video is a 'standard' video and the videoRef exists
  const executeIfStandardVideo = (fn: () => void) => {
    if (isStandardVideo && videoRef.value) {
      fn()
    }
  }

  // Toggle play/pause state of the video
  const togglePlay = async () => {
    if (!videoRef.value) return
    try {
      isPlaying.value ? videoRef.value.pause() : await videoRef.value.play()
      isPlaying.value = !isPlaying.value
    } catch (error) {
      console.log(error)
    }
  }

  // Toggle mute/unmute state of the video
  const toggleMute = () => {
    if (!videoRef.value) return

    videoRef.value.muted = !videoRef.value.muted
    isMuted.value = videoRef.value.muted
  }

  // Enable Picture-in-Picture mode (if supported)
  const enablePip = () =>
    executeIfStandardVideo(async () => {
      try {
        await videoRef?.value?.requestPictureInPicture()
      } catch (error) {
        console.error('Error entering PiP:', error)
      }
    })

  // Toggle fullscreen mode for the video
  const toggleFullScreen = () =>
    executeIfStandardVideo(() => {
      if (videoRef?.value?.requestFullscreen) {
        videoRef.value.requestFullscreen()
      } else if (videoRef?.value?.webkitEnterFullscreen) {
        videoRef.value.webkitEnterFullscreen()
      } else if (videoRef?.value?.mozRequestFullScreen) {
        videoRef.value.mozRequestFullScreen()
      } else if (videoRef?.value?.msRequestFullscreen) {
        videoRef.value.msRequestFullscreen()
      }
    })

  // Seek the video to a specific time (based on the range input)
  const onSeek = (event: Event) => {
    executeIfStandardVideo(() => {
      const input = event.target as HTMLInputElement
      videoRef.value!.currentTime = Number(input.value)
    })
  }

  // Update the current time and total duration of the video, triggered by 'timeupdate' event
  const onTimeUpdate = () => {
    executeIfStandardVideo(() => {
      const current = videoRef.value!.currentTime
      const total = videoRef.value!.duration

      currentTime.value = formatTime(current)
      duration.value = formatTime(total)
      currentSeconds.value = current
      videoDuration.value = total
    })
  }

  // Get the position of the video commands (play, pause, etc.) based on the type of video (standard or loop).
  const getCommandsPosition = () => {
    if (isStandardVideo) {
      return 'bottom-0 left-0 right-0'
    } else {
      const loopCommandPositionOptions = {
        'top-left': 'top-0 left-0',
        'bottom-right': 'bottom-0 right-0',
      }
      return loopCommandPositionOptions[
        mediaVideo.commandsPosition ?? 'bottom-right'
      ]
    }
  }

  return {
    isStandardVideo,
    isLoopVideo,
    isPlaying,
    isMuted,
    currentTime,
    duration,
    currentSeconds,
    videoDuration,
    srcVideo,
    srcVideoMp4,
    isPipSupported,
    togglePlay,
    toggleMute,
    enablePip,
    toggleFullScreen,
    onSeek,
    onTimeUpdate,
    getCommandsPosition,
  }
}
