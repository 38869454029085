<script setup lang="ts">
import type { EditorialShopTheLookProps } from './EditorialShopTheLook.props'
import type { ProductCardHorizontalProps } from '@design-system/components/UI/UIProductCardHorizontal.props'
import type { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel'

const { isEmporioArmaniWebsite, isEa7Website, isGiorgioArmaniWebsite } =
  useWebsite()

const props = defineProps<EditorialShopTheLookProps>()

const { getProducts, transformProductForQuickBuy } = useShopTheLook()
const products = ref(await getProducts(props.products))

const isOneProduct = ref(products.value.length === 1)
const isLessThanThreeProduct = ref(products.value.length < 3)

const tabletSlidesPerView = computed(() => {
  if (isOneProduct.value) return 1
  return isLessThanThreeProduct.value ? products.value.length : 2.5
})

const mobileSlidesPerView = computed(() => (isOneProduct.value ? 1 : 1.5))

const options: EmblaOptionsType = {
  active: !isOneProduct.value,
  loop: false,
  skipSnaps: true,
  containScroll: false,
  align: 'center',
  breakpoints: {
    '(min-width: 768px)': {
      active: products.value.length >= 2,
    },
    '(min-width: 1024px)': {
      active: products.value.length > 3,
      containScroll: 'trimSnaps',
    },
  },
}

const emblaApi = ref<any>(null)

const onInit = (api: Ref<EmblaCarouselType | undefined>) => {
  if (api.value) {
    emblaApi.value = api.value
    updateButtonStates()
  }
}

const canScrollNext = ref(false)
const canScrollPrev = ref(false)

const updateButtonStates = () => {
  if (!emblaApi?.value) return
  canScrollNext.value = emblaApi.value.canScrollNext()
  canScrollPrev.value = emblaApi.value.canScrollPrev()
}

watchEffect(() => {
  if (!emblaApi?.value) return
  emblaApi.value.on('select', updateButtonStates)
  emblaApi.value.on('reInit', updateButtonStates)
  updateButtonStates()
})

const { closeDialog } = useDialog()

// Open quick buy
const { openQuickBuySlide } = await useUIQuickBuyPopUpSlide()
const openQuickBuyPopUpLayer = (product: ProductCardHorizontalProps) => {
  openQuickBuySlide({
    product: product,
    isQuickBuyPlp: true,
  })
}
</script>

<template>
  <OrganismsModal :id="id" is-shop-the-look class="z-[60]">
    <template #body>
      <div class="grid h-screen grid-cols-12">
        <div
          class="left-side card-container bg-background-secondary relative col-span-full lg:col-span-6"
          :class="{ 'has-hover': hoverMedia }"
        >
          <div
            class="bg-background-secondary relative z-[49] w-full px-4 py-6 lg:hidden"
          >
            <UtilsMarkdown
              :content="title"
              is-preprocessed
              container="span"
              class="block"
              :class="[
                'text-text-primary uppercase',
                isGiorgioArmaniWebsite
                  ? 'md:text-light-3 text-light-4'
                  : 'md:text-medium-3 text-medium-4',
              ]"
            />
          </div>
          <div
            class="card main-media-card mx-auto w-auto lg:col-span-6 lg:h-full lg:w-fit"
          >
            <UIMedia
              v-bind="media"
              class="w-full lg:h-full lg:!object-contain lg:[&>img]:h-screen lg:[&>img]:min-h-full"
              :aspect-ratio="media.media.type === 'video' ? '5/4' : undefined"
            />
          </div>
          <div
            v-if="hoverMedia"
            class="card hover-image-card absolute inset-0 h-full"
          >
            <UIMedia
              v-bind="hoverMedia"
              class="h-full w-full lg:!object-contain"
              :aspect-ratio="
                hoverMedia?.media?.type === 'video' ? '5/4' : undefined
              "
            />
          </div>
        </div>
        <div
          class="right-side bg-background-secondary relative col-span-full flex flex-col pb-[var(--navbar-height)] lg:col-span-6 lg:h-full lg:pb-0"
        >
          <div
            class="col-span-full hidden w-full gap-2 p-6 pt-16 text-center lg:absolute lg:left-0 lg:top-0 lg:block"
          >
            <button
              :aria-label="$ts('close')"
              class="absolute right-8 top-8"
              @click="() => closeDialog(id)"
            >
              <DefaultIconsClose
                aria-hidden="true"
                class="text-text-primary h-8 w-8"
              />
            </button>
            <UtilsMarkdown
              :content="title"
              is-preprocessed
              container="span"
              :class="[
                'text-text-primary uppercase',
                isGiorgioArmaniWebsite
                  ? 'md:text-light-3 text-light-4'
                  : 'md:text-medium-3 text-medium-4',
              ]"
            />
            <UtilsMarkdown
              v-if="subtitle"
              :content="subtitle"
              class="text-light-6 md:text-light-5 text-text-primary px-10 lg:block"
            />
          </div>
          <div class="flex items-center lg:h-full lg:py-32">
            <div class="w-full">
              <div
                v-if="products.length"
                class="relative grid w-full grid-cols-12"
              >
                <div
                  class="relative m-auto hidden cursor-pointer"
                  :class="[
                    { 'lg:block': products.length > 3 },
                    { 'opacity-35': !canScrollPrev },
                  ]"
                  @click="emblaApi?.scrollPrev()"
                >
                  <DefaultIconsChevronLeft
                    :aria-hidden="true"
                    class="h-6 w-6"
                  />
                </div>
                <div
                  class="group col-span-full lg:col-span-10 lg:col-start-2 lg:col-end-12"
                  :class="{ 'flex justify-center': products.length <= 2 }"
                >
                  <UISliderBase
                    v-if="products.length > 1"
                    :options="options"
                    class="h-full"
                    :justify-center="products.length === 2"
                    @init="onInit"
                  >
                    <div
                      v-for="(card, index) in products"
                      :key="index"
                      class="embla__slide grid-borders-grey shrink-0"
                      :style="{
                        '--slides-per-view-mobile': mobileSlidesPerView,
                        '--slides-per-view-tablet': tabletSlidesPerView,
                        '--slides-per-view-desktop': 3,
                      }"
                      :class="{
                        'grid-borders-black':
                          isEmporioArmaniWebsite || isEa7Website,
                      }"
                    >
                      <UIProductTile
                        v-bind="card"
                        class="h-full w-full"
                        @open-quick-buy="
                          () => {
                            openQuickBuyPopUpLayer(
                              transformProductForQuickBuy(card.product)
                            )
                          }
                        "
                      />
                    </div>
                  </UISliderBase>

                  <div
                    v-if="products.length === 1"
                    class="max-w-full p-10 lg:max-w-[50%] lg:p-0"
                  >
                    <UIProductTile
                      v-bind="products[0]"
                      class="max-w-[540px] lg:max-w-full"
                    />
                  </div>
                </div>
                <div
                  class="relative m-auto hidden cursor-pointer"
                  :class="[
                    { 'lg:block': products.length > 3 },
                    { 'opacity-35': !canScrollNext },
                  ]"
                  @click="emblaApi?.scrollNext()"
                >
                  <DefaultIconsChevronRight
                    :aria-hidden="true"
                    class="h-6 w-6"
                  />
                </div>
              </div>
              <div v-else class="text-text-primary text-center">
                <UtilsMarkdown
                  :content="$ts('editorial.shopTheLook.noRelatedProducts')"
                  class="text-light-6 md:text-light-5 p-4 md:p-10 [&>a]:underline"
                  container="p"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </OrganismsModal>
</template>

<style scoped lang="scss">
.embla {
  .embla__slide {
    --slides-per-view-mobile: 1.5;
    --slides-per-view-tablet: 2.5;
    --slides-per-view-desktop: 2;

    width: calc(100% / var(--slides-per-view-mobile));

    @media (min-width: 768px) {
      width: calc(100% / var(--slides-per-view-tablet));
    }

    @media (min-width: 1024px) {
      width: calc(100% / var(--slides-per-view-desktop));
    }
  }
}

.grid-borders-grey {
  border: 1px solid #d9d9d9;
  grid-gap: 1px;
  background-color: var(--colorStrokeCardDefault);
}

.grid-borders-black {
  border: 1px solid var(--colorStrokeCardHover);
  grid-gap: 1px;
  background-color: var(--colorStrokeCardHover);
}

.card-container {
  .card {
    transition: opacity 300ms ease-in-out;
  }

  .main-media-card {
    opacity: 1;
  }

  .hover-image-card {
    opacity: 0;
  }

  &.has-hover {
    &:hover {
      .main-media-card {
        opacity: 0;
      }

      .hover-image-card {
        opacity: 1;
      }
    }
  }
}
</style>
