<script setup lang="ts">
import type {
  UIMediaProps,
  Video,
} from '@design-system/components/UI/UIMedia.props'

const props = defineProps<UIMediaProps>()
const mediaVideo = props.media as Video

const videoElement = ref<HTMLVideoElement | null>(null)

const {
  isStandardVideo,
  isLoopVideo,
  isPlaying,
  isMuted,
  currentTime,
  duration,
  currentSeconds,
  videoDuration,
  srcVideo,
  srcVideoMp4,
  isPipSupported,
  togglePlay,
  toggleMute,
  enablePip,
  toggleFullScreen,
  onSeek,
  onTimeUpdate,
  getCommandsPosition,
} = useVideo(props, videoElement)
const canVideoPlay = () => {
  return !!(
    (videoElement.value?.readyState ?? 0) >= HTMLMediaElement.HAVE_CURRENT_DATA
  )
}
const readyStateHandler = () => {
  if (canVideoPlay()) {
    videoElement.value?.play()
  }
}

onMounted(() => {
  if (mediaVideo.autoplay) {
    if (canVideoPlay()) {
      videoElement.value?.play()
    } else {
      videoElement.value?.addEventListener('loadeddata', readyStateHandler)
    }
  }
})

onBeforeUnmount(() => {
  videoElement.value?.removeEventListener('loadeddata', readyStateHandler)
})
const videoPoster = computed(() => posterVideo(srcVideo.value))
</script>

<template>
  <div>
    <!-- Poster Image -->
    <link
      v-if="fetchPriorityImage"
      as="image"
      :href="videoPoster"
      rel="preload"
      fetchpriority="high"
    />
    <div
      class="h-full"
      :class="{
        'video-container group relative overflow-y-hidden': isStandardVideo,
      }"
    >
      <!-- Video -->
      <video
        ref="videoElement"
        :src="srcVideo"
        v-bind="$attrs"
        class="object-cover"
        :class="`aspect-${aspectRatio}`"
        :autoplay="false"
        :poster="videoPoster"
        loop
        :muted="mediaVideo.muted"
        playsinline
        :tabindex="skipTabNavigation ? -1 : undefined"
        :aria-hidden="skipTabNavigation ? true : undefined"
        @timeupdate="onTimeUpdate"
      ></video>

      <!-- Commands -->
      <div
        v-if="mediaVideo.showCommands"
        class="commands-container text-medium-7 positive-padding absolute z-50 flex gap-4"
        :class="[
          getCommandsPosition(),
          {
            'bg-primitives-white justify-between bg-opacity-80 py-4 transition-transform group-hover:translate-y-0 lg:translate-y-full':
              isStandardVideo,
            [`text-editorial-${mediaVideo.commandsColor} justify-end py-4 lg:py-8`]:
              isLoopVideo,
          },
        ]"
        @click.stop
      >
        <input
          v-if="isStandardVideo"
          class="absolute left-0 right-0 top-0 -translate-y-1/2 cursor-pointer accent-[#000]"
          type="range"
          min="0"
          :max="videoDuration"
          :value="currentSeconds"
          @input="onSeek"
        />
        <div
          class="left-commands flex items-center"
          :class="{
            'gap-5 group-hover:flex lg:hidden': isStandardVideo,
            'gap-4': isLoopVideo,
          }"
        >
          <button
            :aria-label="
              isPlaying ? $ts('accessibility.pause') : $ts('accessibility.play')
            "
            @click.stop.prevent="togglePlay"
          >
            <template v-if="isPlaying">
              <DefaultIconsPause
                v-if="isStandardVideo"
                class="h-6 w-6"
                aria-hidden="true"
              />
              <DefaultIconsPauseCircle
                v-if="isLoopVideo"
                class="h-7 w-7"
                aria-hidden="true"
              />
            </template>
            <template v-else>
              <DefaultIconsPlay
                v-if="isStandardVideo"
                class="h-6 w-6"
                aria-hidden="true"
              />
              <DefaultIconsPlayCircle
                v-if="isLoopVideo"
                class="h-7 w-7"
                aria-hidden="true"
              />
            </template>
          </button>
          <button
            :aria-label="
              isMuted ? $ts('accessibility.unmute') : $ts('accessibility.mute')
            "
            @click.stop.prevent="toggleMute"
          >
            <DefaultIconsVolumeMute
              v-if="isMuted"
              :class="[isStandardVideo ? 'h-6 w-6' : 'h-7 w-7']"
              aria-hidden="true"
            />
            <DefaultIconsVolumeOn
              v-else
              :class="[isStandardVideo ? 'h-6 w-6' : 'h-7 w-7']"
              aria-hidden="true"
            />
          </button>

          <div v-if="isStandardVideo" class="flex gap-4">
            {{ currentTime }} <span>|</span> {{ duration }}
          </div>
        </div>
        <div
          v-if="isStandardVideo"
          class="right-commands items-center gap-5"
          :class="{
            'flex group-hover:flex lg:hidden': isStandardVideo,
            flex: isLoopVideo,
          }"
        >
          <button
            v-if="isPipSupported"
            :aria-label="$ts('accessibility.enablePip')"
            @click="enablePip"
          >
            <DefaultIconsPIP class="h-6 w-6" aria-hidden="true" />
          </button>
          <button
            :aria-label="$ts('accessibility.enableFullscreen')"
            @click="toggleFullScreen"
          >
            <DefaultIconsFullscreen class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
input[type='range'] {
  appearance: none;
  background: transparent;
  cursor: pointer;
  border-radius: 0;
  /* iOS */

  /******** Chrome, Safari, Opera and Edge Chromium styles ********/
  // slider track
  &::-webkit-slider-runnable-track {
    background: #000;
    height: 0.125rem;
  }

  // slider thumb
  &::-webkit-slider-thumb {
    appearance: none;
    border-radius: 50%;
    margin-top: calc((0.125rem / 2) - (0.75rem / 2));
    background-color: #000;
    border-color: #000;
    height: 0.75rem;
    width: 0.75rem;
    cursor: grab;
  }

  /*********** Firefox styles ***********/
  // slider track
  &::-moz-range-track {
    height: 0.125rem;
    background: #000;
  }

  // slider thumb
  &::-moz-range-thumb {
    appearance: none;
    border-radius: 50%;
    margin-top: calc((0.125rem / 2) - (0.75rem / 2));
    background-color: #000;
    border-color: #000;
    height: 0.75rem;
    width: 0.75rem;
    cursor: grab;
  }
}
</style>
