<script setup lang="ts">
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'
import type { UIMediaProps, Image, Hotspot, InfoHotspot } from './UIMedia.props'
import { v4 as uuidv4 } from 'uuid'

const props = withDefaults(defineProps<UIMediaProps>(), {
  fetchPriorityImage: false,
})
const { openDialog } = useDialog()

const srcImage = computed(() => {
  if (props.media.type === 'image') {
    const image = props.media as Image
    return image?.srcImage?.public_id ?? image?.srcImage?.secure_url ?? '#'
  } else return
})

const adaptedRatio = computed(() =>
  props.aspectRatio ? adaptRatio(props.aspectRatio) : undefined
)

const hotspots = props.mediaHotspot?.hotspots ?? []
const hotspotsProduct = ref<Hotspot[]>([])
const selectors = ref<string[]>([])
const areHotspotsLoaded = ref(false)

onMounted(async () => {
  for (const item of hotspots) {
    if (item?.target === 'product' && props.infoHotspot) {
      item.index = hotspots.indexOf(item) + 1
      hotspotsProduct.value.push(item)
      selectors.value.push(
        getObjectBySelector(props.infoHotspot, item.selector)?.product!
      )
    }
  }
  areHotspotsLoaded.value = true
})

const getHotspotPosition = (hotspot: Hotspot) => ({
  left: `${hotspot.points.x * 100}%`,
  top: `${hotspot.points.y * 100}%`,
})

const uniqueId = uuidv4()
const uniqueShopTheLookModalId = `shop-the-look-${uniqueId}`
defineExpose({ openDialog, uniqueShopTheLookModalId })
const focusElement = (event: Event) => {
  setTimeout(() => {
    const target = event.target as HTMLElement
    if (target && typeof target.focus === 'function') {
      target.focus()
    }
  }, 100)
}

const { openDialog: openHotspotDialog } = useDialog(
  SIDEBAR_IDS.hotspotModalProduct
)

const productSelected = ref<InfoHotspot | null>(null)
const indexSelected = ref(0)

const { setHotspotModalState } = useHotspotModal()

const onClick = async (event: Event, hotspot: Hotspot) => {
  event.preventDefault()
  event.stopPropagation()
  productSelected.value = null
  await nextTick()
  indexSelected.value = hotspotsProduct.value.indexOf(hotspot)

  productSelected.value = getObjectBySelector(
    props?.infoHotspot!,
    hotspot.selector
  )!

  const hotSpotModalProps = {
    productSelected: getObjectBySelector(
      props?.infoHotspot!,
      hotspot.selector
    )! as InfoHotspot,
    hotspotsProduct: hotspotsProduct.value,
    index: hotspotsProduct.value.indexOf(hotspot),
    showNumberHotspot: true,
    infoHotspot: props.infoHotspot,
    src: srcImage.value,
    alt: props.media.type === 'image' ? (props.media?.alt ?? '') : '',
  }
  setHotspotModalState(hotSpotModalProps)

  openHotspotDialog(SIDEBAR_IDS.hotspotModalProduct)
}

const getObjectBySelector = (data: InfoHotspot[], selector: string) => {
  return data.find((item: InfoHotspot) => item.selector === Number(selector))
}
const widgetPositionOptions = {
  'top-left': 'absolute top-6 left-6',
  'top-right': 'absolute top-6 right-6',
  'bottom-left': 'absolute bottom-6 left-6',
  'bottom-right': 'absolute bottom-6 right-6',
}

const widgetOrientationOptions = {
  vertical: 'flex flex-col gap-4',
  horizontal: 'flex gap-4',
  grouped: 'grid grid-cols-2 gap-4',
}

function handleHotspotHover(
  target: HTMLElement,
  hotspotElements: NodeListOf<Element>
) {
  hotspotElements.forEach(el => {
    if (el.id === target.id) el.classList.add('hotspot-hover-animation')
    else el.classList.add('hotspot-hide-animation')
  })
}

function handleHotspotUnhover(
  target: HTMLElement,
  hotspotElements: NodeListOf<Element>
) {
  hotspotElements.forEach(el => {
    if (el.id === target.id) el.classList.remove('hotspot-hover-animation')
    else el.classList.remove('hotspot-hide-animation')
  })
}

const hoverAnimationHandler = (event: Event) => {
  if (!(event.target instanceof HTMLElement)) return

  const hotspotElements = document.querySelectorAll('.hotspot-button')

  if (event.type === 'mouseover')
    handleHotspotHover(event.target, hotspotElements)
  else if (event.type === 'mouseleave')
    handleHotspotUnhover(event.target, hotspotElements)
}
</script>

<template>
  <div class="relative">
    <template v-if="shopTheLook">
      <button
        class="shop-the-look-button hover:bg-background-secondary bg-primitives-off-white group absolute right-4 top-4 z-10 flex items-center justify-center overflow-hidden rounded-full p-2 transition-all duration-700 ease-in-out lg:right-6 lg:top-6 lg:p-[14px]"
        :class="`text-editorial-${shopTheLook.iconShopTheLookColor ?? 'black'}`"
        :aria-label="$ts('aria-labels.shopTheLook')"
        @click.stop.prevent="openDialog(uniqueShopTheLookModalId)"
      >
        <DefaultIconsCart class="h-5 w-5" aria-hidden="true" />
        <span
          class="shop-the-look-label text-book-6 relative inline-block w-0 whitespace-nowrap px-0 text-white opacity-0 transition-all duration-300 ease-in-out group-hover:px-2 group-hover:opacity-100"
        >
          {{ $ts('plp.buy') }}
        </span>
      </button>
      <EditorialShopTheLook
        v-bind="shopTheLook"
        :id="uniqueShopTheLookModalId"
      />
    </template>
    <!-- WIDGETS -->
    <template v-if="widgets && widgets?.content?.length">
      <div
        :class="[
          widgetPositionOptions[widgets.position ?? 'top-right'],
          widgetOrientationOptions[widgets.orientation ?? 'vertical'],
        ]"
      >
        <div
          v-for="(widget, index) of widgets.content"
          :key="`widget-${index}`"
          class="flex flex-col items-center justify-center gap-1"
          :class="
            widgets.content?.length === index + 1 &&
            widgets.content?.length % 2 !== 0
              ? 'col-span-full'
              : 'col-span-1'
          "
        >
          <NuxtPicture
            :img-attrs="{ class: 'h-8 w-full object-cover object-top' }"
            :src="widget?.image?.public_id ?? widget?.image?.secure_url ?? '#'"
            :alt="widget.alt ?? ''"
            fit="cover"
            provider="cloudinary"
          />
          <span
            v-if="widget.text"
            class="text-text-primary text-book-7 flex justify-center"
            >{{ widget.text }}</span
          >
        </div>
      </div>
    </template>
    <ClientOnly v-if="areHotspotsLoaded">
      <div v-for="(hotspot, index) in hotspots" :key="hotspot.id">
        <UtilsTooltip
          v-if="hotspot.target === 'link'"
          class="absolute z-40"
          :style="getHotspotPosition(hotspot)"
          :aria-label="$ts('accessibility.hotspotTooltipButton')"
          type="hotspot-modal"
          :stop-event-propagation="true"
          placement="bottom"
          :distance="8"
        >
          <template #target>
            <div
              class="hotspot-button text-book-8"
              tabindex="0"
              @click="focusElement"
            >
              {{ showNumberHotspot ? index + 1 : '' }}
            </div>
          </template>
          <template #content>
            <div
              v-if="
                infoHotspot &&
                getObjectBySelector(infoHotspot, hotspot?.selector)?.link
              "
              class="text-book-5 z-10 max-w-[400px] p-10 text-center"
            >
              <UILink
                class="flex items-center p-0"
                v-bind="
                  getObjectBySelector(infoHotspot, hotspot?.selector)?.link!
                "
              />
            </div>
          </template>
        </UtilsTooltip>
      </div>

      <div v-for="(hotspot, index) in hotspotsProduct" :key="hotspot.id">
        <div v-if="hotspot.target === 'product'">
          <button
            :id="`hotspot-${hotspot.id}-${index}`"
            class="hotspot-button text-book-8"
            :style="getHotspotPosition(hotspot)"
            :aria-label="$ts('accessibility.hotspotTooltipButton')"
            @click="onClick($event, hotspot)"
            @mouseover="hoverAnimationHandler"
            @mouseleave="hoverAnimationHandler"
          >
            {{ showNumberHotspot ? hotspot.index : '' }}
          </button>
        </div>
      </div>
    </ClientOnly>
    <NuxtPicture
      v-if="media.type === 'image'"
      provider="cloudinary"
      v-bind="$attrs"
      :class="`block aspect-${aspectRatio}`"
      :src="srcImage"
      :alt="media.alt ?? ''"
      :sizes="media.sizes ?? 'sm:100vw lg:80vw'"
      :modifiers="adaptedRatio ? { aspectRatio: adaptedRatio } : {}"
      fit="cover"
      :img-attrs="{
        ...$attrs,
        fetchpriority: fetchPriorityImage ? 'high' : undefined,
        class: `${$attrs.class} ${aspectRatio ? `aspect-${aspectRatio}` : ''} object-cover`,
      }"
      :loading="!fetchPriorityImage ? 'lazy' : undefined"
    />

    <div v-if="media.type === 'video'" class="relative h-full w-full">
      <UIMediaVideo v-bind="{ ...props, ...$attrs }" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.hotspot-button {
  @apply bg-neutral-white absolute z-30 flex h-6 w-6 items-center justify-center rounded-full;
  transform: translate(-50%, -50%);
  &:before {
    content: '';
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    border: 1px solid white;
    pointer-events: none;
  }
  &:focus {
    outline: 1px solid white;
    outline-offset: 3px;
  }
  &:not(:focus):before {
    animation: pulse-animation 5s infinite;
    animation-delay: 3.5s;
  }

  &:hover:before {
    border: transparent;
  }
}

.hotspot-hover-animation {
  &:after {
    content: '';
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    border: 1px solid white;
    pointer-events: none;
  }
  &:not(:focus):after {
    animation: pulse-animation 5s infinite;
  }
}

.hotspot-hide-animation {
  &:before {
    border: transparent;
  }
}

@keyframes pulse-animation {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  30% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.shop-the-look-button {
  transition: outline 0.4s 0.25s;
  outline: 1px solid currentColor;
  &:hover {
    transition: outline 0.4s;
    .shop-the-look-label {
      width: unset;
    }
    svg {
      color: var(--colorBackgroundPrimary);
    }
  }
  .shop-the-look-label {
    color: var(--colorBackgroundPrimary);
  }
}

.placeholder-hotspot-reset-animation {
  @apply bg-neutral-white absolute z-30 flex h-6 w-6 items-center justify-center rounded-full;
  transform: translate(-50%, -50%);
}
</style>
